<template>
  <div id="userOrder">
    <Dialog ref="dialog" :config="config" />
    <div class="tabsContainer">
      <el-tabs v-model="form.status" @tab-click="handleClick" ref="tabs">
        <!-- 所有订单 -->
        <el-tab-pane name="all">
          <template #label>
            <div>
              {{ $fanyi('所有订单') }}
            </div>
            <div class="num_box">
              {{ `(${allTabText})` }}
            </div>
          </template>
        </el-tab-pane>
        <!-- 临时保存 -->
        <el-tab-pane name="temporary">
          <template #label>
            <div>
              {{ $fanyi('临时保存的订单') }}
            </div>
            <div class="num_box">
              {{ `(${temporaryTabText})` }}
            </div>
          </template>
        </el-tab-pane>
        <!-- 等待报价 报价中 -->
        <el-tab-pane name="offer">
          <template #label>
            <div>
              {{ $fanyi('等待报价') }}
            </div>
            <div class="num_box">
              {{ `(${awaitQuoteTabText})` }}
            </div>
          </template>
        </el-tab-pane>

        <!-- 待付款 -->
        <el-tab-pane name="obligation">
          <template #label>
            <div>
              {{ $fanyi('等待支付') }}
            </div>
            <div class="num_box">
              {{ `(${obligationTabText})` }}
            </div>
          </template>
        </el-tab-pane>
        <!-- 等待财务确认 == 支付中 -->
        <el-tab-pane name="Pending financial confirmation">
          <template #label>
            <div>
              {{ $fanyi('支付中') }}
            </div>
            <div class="num_box">
              {{ `(${WaitforfinancialconfirmationText})` }}
            </div>
          </template>
        </el-tab-pane>

        <!-- 处理中 -->
        <el-tab-pane name="shelves">
          <template #label>
            <div>
              {{ $fanyi('处理中') }}
            </div>
            <div class="num_box">
              {{ `(${ProcessingText})` }}
            </div>
          </template>
        </el-tab-pane>
        <!-- 上架完了 -->
        <el-tab-pane name="warehouse">
          <template #label>
            <div>
              {{ $fanyi('上架完了') }}
            </div>
            <div class="num_box">
              {{ `(${InstockText})` }}
            </div>
          </template>
        </el-tab-pane>
        <!-- 国际运输中 -->
        <el-tab-pane name="In transit">
          <template #label>
            <div>
              {{ $fanyi('国际运输中') }}
            </div>
            <div class="num_box">
              {{ `(${IntransitText})` }}
            </div>
          </template>
        </el-tab-pane>

        <!-- 已签收 -->
        <el-tab-pane name="Delivered">
          <template #label>
            <div>
              {{ $fanyi('已签收') }}
            </div>
            <div class="num_box">
              {{ `(${DeliveredText})` }}
            </div>
          </template>
        </el-tab-pane>

        <el-tab-pane name="delete">
          <template #label>
            <div>
              {{ $fanyi('已取消订单') }}
            </div>
            <div class="num_box">
              {{ `(${CancelText})` }}
            </div>
          </template>
        </el-tab-pane>

      </el-tabs>
      <div class="question_box">
        <img src="../../../../assets/question.svg" alt="" @click="toBlog">
      </div>
    </div>
    <!-- 列表区域 -->
    <div class="tableBox">
      <div class="searchBox">
        <div class="myInput">
          <i class="el-icon-search"></i>
          <input type="text" v-model="form.searchText" @keydown.enter="getData(form)"
            :placeholder="$fanyi('请输入订单号搜索')" />
        </div>
        <div class="myInput">
          <i class="el-icon-search"></i>
          <input type="text" v-model="form.porder_sn" @keydown.enter="getData(form)" :placeholder="$fanyi('请输入装箱单号')" />
        </div>
        <el-date-picker v-model="time" type="datetimerange" clearable :start-placeholder="$fanyi('开始日期')"
          :range-separator="$fanyi('')" :end-placeholder="$fanyi('结束日期')" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
          :default-time="['00:00:00', '23:59:59']" @change="setFromDate">
        </el-date-picker>
        <button class="searchBtn" @click="searchBtn">
          {{ $fanyi('搜索') }}
        </button>
        <button class="cancelledBtn" @click="clearBtn">
          {{ $fanyi('清除') }}
        </button>
      </div>

      <div class="dataBody" v-loading="loading">
        <el-table :data="tableData.data" border :header-cell-style="{
          'text-align': 'center',
          background: '#F0F0F0',
          'font-size': '14px',
          height: '56px',
          'font-weight': '400',
          'line-height': '18px',
          color: '#222222',
          'background-color': '#F6F6F6',
        }" :cell-style="{ 'text-align': 'center' }">
          <!--  序号-->
          <el-table-column key="t2" type="index" label="NO">
            <template slot-scope="scope">
              <div>
                {{ (Number(form.page) - 1) * 10 + Number(scope.$index) + 1 }}
              </div>
            </template>
          </el-table-column>

          <!-- 订单号 -->

          <el-table-column key="t3" :width="form.status != 'temporary' ? '200' : ''" :label="$fanyi('订单号')">
            <template slot-scope="scope">
              <div class="orderNumber">
                <button @click="
                  $fun.toPage(
                    '/orderDetails?type=' +
                    scope.row.statusName +
                    '&order_sn=' +
                    scope.row.order_sn
                    , true)
                  ">
                  {{ scope.row.order_sn }}
                </button>
              </div>
            </template>
          </el-table-column>
          <!-- 提出日期  -->
          <el-table-column key="t4" v-if="form.status != 'temporary'" :label="$fanyi('提出日期')">
            <template slot-scope="scope">
              <div style="white-space: pre-line; "> {{ scope.row.created_at }} </div>

            </template>
          </el-table-column>
          <!-- 付款日期  -->
          <el-table-column key="t5" v-if="form.status != 'temporary' && form.status != 'obligation'" :label="form.status == 'delete' ? $fanyi('订单取消日期')
            : $fanyi('付款日期')">
            <template slot-scope="scope">
              <div style="white-space: pre-line; ">
                {{
                  scope.row.delete_time
                    ? scope.row.delete_time
                    : scope.row.order_time_node
                      ? scope.row.order_time_node.datetime_purchase_complete
                        ? scope.row.order_time_node.datetime_purchase_complete
                        : '————'
                      : '————'
                }}
              </div>

            </template>
          </el-table-column>
          <!-- 购买完成日期  -->
          <el-table-column key="t6" v-if="form.status != 'temporary' &&
            form.status != 'obligation' &&
            form.status != 'delete'
          " :label="$fanyi('购买完成日期')">
            <template slot-scope="scope">
              <div style="white-space: pre-line; ">
                {{
                  scope.row.order_time_node
                    ? scope.row.order_time_node.datetime_pay_confirm
                      ? scope.row.order_time_node.datetime_pay_confirm
                      : '————'
                    : '————'
                }}
              </div>
            </template>
          </el-table-column>
          <!-- 订单状态 -->
          <el-table-column key="t7" :label="$fanyi('订单状态')">
            <template slot-scope="scope">
              <div>
                {{ textConversion(scope.row.statusName) }}
              </div>
            </template>
          </el-table-column>


          <!-- 操作 只有临时保存和待付款可以修改 -->
          <el-table-column key="t8" :width="form.status == 'obligation' ? '380' : ''"
            v-if="['temporary', 'obligation'].indexOf(form.status) != -1" :label="$fanyi('操作')">
            <template slot-scope="scope">
              <div class="Controls">
                <button v-if="scope.row.statusName == 'obligation' || scope.row.statusName == 'temporary'"
                  @click="delOrder(scope.row.order_sn)" class="quxiao">{{ $fanyi('取消') }}</button>

                <button v-if="form.status === 'obligation' && scope.row.statusName == 'obligation'
                " class="paymentBtn" @click="
                  $fun.toPage(
                    '/orderDetails?type=' +
                    scope.row.statusName +
                    '&order_sn=' +
                    scope.row.order_sn
                  )
                  ">
                  {{ $fanyi('前往付款') }}
                </button>
              </div>
            </template>
          </el-table-column>
          <!-- 装箱单 -->
          <el-table-column key="t9" width="310" v-if="['all', 'In transit', 'Delivered'].indexOf(form.status) != -1"
            :label="$fanyi('装箱单')">
            <template slot-scope="scope">
              <div class="Packinglist" v-if="scope.row.porder_sn">
                <div class="Dispatchnumber">
                  <p @click="$fun.toPage('/deliveryDetails?order_sn=' + item.porder_sn + '&status=' + item.status, true)"
                    v-for="(item, index) in scope.row.porder_sn" :key="index"><span>{{ item.porder_sn }}</span> <span
                      class="Orderstatus"> {{ $fanyi(item.statusName) }}</span></p>
                </div>

              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="allpag">
        <span></span>
        <el-pagination @current-change="handleCurrentChange" :current-page="form.currentPage" :page-size="10"
          :page-sizes="[10, 20, 30, 50]" layout=" prev, pager, next" :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>

import Dialog from '../../../../components/public/Dialog'

export default {
  name: 'orderlist',
  data() {
    return {
      time: null,
      form: {
        status: 'all',
        page: 1,
        pageSize: 10,
        searchText: '',
        porder_sn: '',// 配送单号
        startTime: '',
        endTime: '',
      },
      tableData: {
        data: []
      },
      total: 0,

      config: {
        top: '20vh',
        width: '500px',
        title: this.$fanyi('提示'),
        btnTxt: [this.$fanyi('确认'), this.$fanyi('取消')],
      },
      allTabText: 0,
      temporaryTabText: 0,
      awaitQuoteTabText: 0,
      obligationTabText: 0,
      WaitforfinancialconfirmationText: 0,
      ProcessingText: 0,
      InstockText: 0,
      IntransitText: 0,
      DeliveredText: 0,
      CancelText: 0,
      all: 0,

      //商品列表各种类商品数据
      ordersSumList: {
        temporary: 0,
        delete: 0,
        purchased: 0,
        purchase: 0,
        obligation: 0,
        offer: 0,
        all: 0,
      },
    }
  },
  components: { Dialog },
  computed: {},
  watch: {
    $route(to, from) {
      this.form.page = 1
      this.form.pageSize = 10
      if (this.$route.query.status) {
        this.form.status = this.$route.query.status
        this.$router.push({
          query: {},
        })
        this.getData(this.form)
      }
      this.ordersSum()
    },
    deep: true,
  },
  created() {
    this.form.page = 1
    this.form.pageSize = 10
    if (this.$route.query.status) {
      this.form.status = this.$route.query.status
      this.getData(this.form)
    } else {
      this.getData(this.form,)
    }
    this.ordersSum()
  },
  methods: {
    textConversion(value) {
      // 把接口返回的状态名转换成英语
      let list = [
        {
          key: 'temporary',
          value: this.$fanyi('预购订单'),
        },
        {
          key: 'offer',
          value: this.$fanyi('等待报价'),
        },
        {
          key: 'obligation',
          value: this.$fanyi('等待支付'),
        },
        {
          key: 'purchase',
          value: this.$fanyi('正在购买'),
        },
        {
          key: 'purchased',
          value: this.$fanyi('购买完成'),
        },
        {
          key: 'Pending financial confirmation',
          value: this.$fanyi('支付中'),
        },
        {
          key: 'cancel',
          value: this.$fanyi('已取消(订单状态)'),
        },
        {
          key: 'delete',
          value: this.$fanyi('已删除'),
        },
        {
          key: 'shelves',
          value: this.$fanyi('上架中'),
        },
        {
          key: 'warehouse',
          value: this.$fanyi('上架完了'),
        },
        {
          key: 'shipping',
          value: this.$fanyi('出货完成'),
        }, {
          key: 'In transit',
          value: this.$fanyi('国际运输中'),
        },
        {
          key: 'Delivered',
          value: this.$fanyi('已签收'),
        },
      ]
      for (let i in list) {
        if (list[i].key == value) {
          return list[i].value
        }
      }

      return value
    },
    handleClick(val) {

      this.form.status = val.name
      this.form.page = 1
      this.getData(this.form)
    },
    // 点击搜索
    searchBtn() {
      this.form.page = 1
      this.getData(this.form)
    },
    //点击清除
    clearBtn() {
      this.form.searchText = ''
      this.form.porder_sn = ''
      this.time = null
      this.form.startTime = ''
      this.form.endTime = ''
      this.form.page = 1
      this.getData(this.form)
    },
    //点击去博客
    toBlog() {
      window.open('https://blog.rakumart.us/order-status/', '_blank')
    },
    // 选择器选择时间触发
    setFromDate() {
      if (this.time) {
        this.form.startTime = this.time[0]
        this.form.endTime = this.time[1]
      } else {
        this.form.startTime = ''
        this.form.endTime = ''
      }
    },

    // 获取每个状态的订单数量
    ordersSum() {
      this.$api
        .ordersSumV2()
        .then((res) => {
          if (res.data.length == 0) {
            this.ordersSumList.all = 0
            return
          }
          this.allTabText = res.data.all || 0
          this.temporaryTabText = res.data.temporarily_saved || 0
          this.awaitQuoteTabText = res.data.awaiting_quote || 0
          this.obligationTabText = res.data.awaiting_payment || 0
          this.WaitforfinancialconfirmationText = res.data.pending || 0

          this.ProcessingText = res.data.processing || 0

          this.InstockText = res.data.in_stock || 0

          this.IntransitText = res.data.in_transit || 0

          this.DeliveredText = res.data.delivered || 0
          this.CancelText = res.data.closed || 0



          this.ordersSumList = res.data
          if (this.form.status != 'all') {
            this.gettabsoffset()
          }
        })

    },
    gettabsoffset(tab) {
      this.$nextTick(() => {
        if (!this.$refs.tabs) return
        const currentEL = this.$refs.tabs.$el.querySelector('.el-tabs__item.is-active') // 拿到当前高亮的

        const barEl = this.$refs.tabs.$el.querySelector('.el-tabs__active-bar')
        barEl.style.transform = `translateX(${currentEL.offsetLeft + 10}px)`
      })
    },
    // 获取数据，
    getData(datas) {
      // this.tableData = {
      //   data: []
      // }

      this.loading = true
      this.$api
        .orderList(datas)
        .then((res) => {
          this.loading = false
          this.tableData = res.data
          this.total = res.data.total
          this.form.currentPage = res.data.currentPage
          this.$forceUpdate()

        })
    },
    // 删除订单,
    delOrder(order_sn) {
      this.$refs.dialog.open(
        this.$fanyi('是否取消当前订单?'),
        () => {
          let datas = {
            order_sn: order_sn,
            deleteReason: this.$fanyi('删除'),
          }
          this.$api.orderRecycling(datas).then((res) => {
            if (res.code != 0) return
            this.getData(this.form)
            this.ordersSum()
          })

        },

      )
    },
    handleCurrentChange(val) {
      this.form.page = val
      this.getData(this.form)
    },
  },
}
</script>
<style lang="scss" scoped>
#userOrder {
  @import '../../../../css/mixin';
  width: 1180px;
  background: #FFFFFF;
  border-radius: 6px;
  border: 1px solid #E2E2E2;

  /deep/.el-range-separator {
    width: 3px;
    height: 20px;
    // background-color: pink;
    background: url('../../../../assets/newImg/order/date.png') no-repeat center;
  }

  /deep/ .el-date-editor {
    position: relative;

    .el-input__icon {
      display: none;
    }
  }

  .tabsContainer {
    height: 60px;
    border-bottom: 1px solid #E2E2E2;
    border-radius: 0;
    width: 1180px;
    display: flex;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;

    .question_box {

      width: 30px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 20px;
        height: 20px;
        cursor: pointer;

      }

    }

    /deep/ .el-tabs {
      max-width: 1140px;
      height: 50px;

      .el-tabs__header {
        margin: 0;
        height: 100%;

        .el-tabs__nav-wrap {
          height: 100%;

          .el-tabs__nav-scroll {
            height: 100%;
          }
        }
      }

      .el-tabs__nav {
        height: 100%;
        display: flex;
        justify-content: space-between;

      }

      .el-tabs__active-bar {
        display: none;
      }

      .el-tabs__item {
        min-width: 98px;
        height: 100%;
        padding: 0 15px;
        color: #222222;
        line-height: normal;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-right: 1px solid #E2E2E2;

        .num_box {
          color: #FF730B;
        }

        &.is-active {
          font-weight: 400;
          color: #ffffff;
          background-color: #FF730B;

          .num_box {
            color: #ffffff;
          }
        }
      }

      #tab-all {
        &.is-active {
          border-radius: 6px 0 0 0;
        }
      }

      .el-icon-arrow-left {
        font-size: 14px;
      }

      .el-icon-arrow-right {
        font-size: 14px;
      }
    }

    /deep/.el-tabs__nav {
      bottom: 1px;
    }

    /deep/ .el-tabs__nav-wrap::after {
      display: none;
    }
  }

  .tableBox {
    padding: 30px;
    padding-top: 20px;
    border-radius: 6px;

    .searchBox {
      display: flex;
      margin-bottom: 20px;

      .myInput {
        width: 260px;
        height: 40px;
        border-radius: 4px;
        position: relative;
        display: flex;
        align-items: center;
        // overflow: hidden;
        margin-right: 10px;

        >i {
          width: 20px;
          height: 24px;
          margin: 9px 11px 9px 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
        }

        >input {
          border: 1px solid #e2e2e2;
          width: 100%;
          height: 100%;
          border-radius: 4px;
          font-size: 14px;
          padding-left: 40px;
          padding-right: 10px;
          transition: 0.3s;

          &:focus {
            border-color: #1e2997;
          }
        }
      }

      /deep/.el-date-editor {
        border-radius: 4px;
        width: 350px;
        height: 40px;
        margin-right: 20px;
        line-height: 32px !important;

        .el-input__inner {
          padding: 0px !important;
          border-radius: 4px;
        }

        .el-range-input {
          font-size: 14px;
          border-radius: 4px;
        }

        .el-range-separator {
          width: 50px;
          line-height: 23px;
        }

        .el-range__icon {
          line-height: 25px;
        }
      }

      /deep/ .el-input__inner {
        border-radius: 4px;
      }

      .searchBtn {
        width: 80px;
        height: 40px;
        background: #ff730b;
        border-radius: 4px;
        font-family: Roboto-Regular, Roboto;
        font-weight: 400;
        color: #ffffff;
        line-height: 24px;

        font-size: 14px;
        line-height: 32px;
        text-align: center;
      }

      .cancelledBtn {
        min-width: 80px;
        height: 40px;
        border-radius: 4px;
        border: 1px solid #E2E2E2;
        font-size: 16px;
        color: #222222;
        margin-left: 10px;


      }
    }

    .dataBody {
      min-height: 128px;

      /deep/.el-table {

        .el-table__header {
          .cell {
            line-height: 18px;
          }
        }

        .orderNumber {
          button {
            font-size: 14px;
            color: #ff730b;
            line-height: 20px;


            &:hover {
              text-decoration: underline;
              color: #ff730b;
            }
          }
        }

        .Controls {
          display: flex;

          justify-content: center;

          .quxiao {
            width: 120px;
            height: 40px;

            border-radius: 4px;
            text-align: center;
            font-size: 14px;
            line-height: 40px;
            font-family: Roboto-Regular, Roboto;
            font-weight: 400;
            color: #698cff;



          }

          .paymentBtn {
            width: 120px;
            height: 40px;
            background: #ff730b;
            border-radius: 4px;
            font-family: Roboto-Regular, Roboto;
            font-weight: 400;

            line-height: 40px;
            margin-left: 20px;

            color: #ffffff;
            font-size: 14px;
          }


        }

        .Packinglist {
          display: flex;
          align-items: center;
          justify-content: center;

          .Dispatchnumber {



            p {
              width: 100%;
              line-height: 20px;
              margin-bottom: 3px;
              display: flex;
              align-items: center;



              span {
                font-weight: 400;
                font-size: 14px;

                color: #FF730B;

                &:hover {
                  cursor: pointer;
                  text-decoration: underline;
                }

                &:first-child {
                  display: flex;
                  width: 170px;
                }

                &:last-child {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  width: 80px;
                }

              }

              .Orderstatus {
                flex: 1;
                margin-left: 30px;
                word-break: keep-all;
                color: #333;

                &:hover {
                  cursor: pointer;
                  text-decoration: none;
                }

              }


            }
          }

          .Orderstatus {
            flex: 1;
            word-break: keep-all;


          }

        }

      }


    }

    .allpag {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 25px;

      .el-pagination {
        display: flex;
        align-items: center;
      }

      >a {
        display: inline-block;
        border: 1px solid #dcdfe6;
        height: 28px;
        // background-color: white;
        text-align: center;
        padding: 0 10px;
        font-size: 13px;
        line-height: 28px;
        border-radius: 0;
      }

      /deep/.btn-prev {
        width: 40px;
        height: 40px;
        background: #f5f5f5;
        border-radius: 20px;
        padding: 0;
        margin-right: 30px;

        .el-icon {
          font-size: 15px;
        }
      }

      /deep/.btn-next {
        width: 40px;
        height: 40px;
        background: #f5f5f5;
        border-radius: 20px;
        padding: 0;
        margin-left: 30px;

        .el-icon {
          font-size: 15px;
        }
      }

      /* 分页被选中的页码样式 */
      /deep/ .el-pager li {
        margin: 0 10px;
        // border-radius: 0;
        min-width: 20px !important;
        font-size: 14px;
        height: 30px;
        color: #999999;
        // box-shadow: 0 0 6px 0 rgba(61, 61, 61, 0.1);

        &:hover {
          color: #ff730b;
        }

        &.active {
          color: #ff730b;
          border-bottom: 2px solid #ff730b;
          width: 20px !important;
        }
      }
    }
  }



}

.NoDaTa {
  font-size: 14px;
  color: #909399;
  text-align: center;
  height: 80px;
  line-height: 80px;
  border-left: solid 1px #ededed;
  border-right: solid 1px #ededed;
  border-bottom: solid 1px #ededed;
}
</style>
